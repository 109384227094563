.make-payment-page {
  min-height: 100vh;
}

.make-payment-page .inner-payment {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.make-payment-page .inner-payment .right {
  width: 440px;
}

.make-payment-page .inner-payment .right .top {
  margin-bottom: 16px;
}

.make-payment-page .inner-payment .right .wallet-card {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background: linear-gradient(90deg, #0fd850 0%, #f9f047 100%);
  margin-top: 30px;
  transition: 0.3s;
  cursor: pointer;
}

.make-payment-page .inner-payment .right .wallet-card:hover {
  transform: translateY(-10px);
}

.make-payment-page .inner-payment .right .wallet-card span {
  position: absolute;
  top: 16px;
  left: 24px;
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
}

.make-payment-page .inner-payment .right .wallet-card h6 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
}

.make-payment-page .inner-payment .right .wallet-card h6 strong {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.make-payment-page .inner-payment .right .wallet-card em {
  position: absolute;
  bottom: 16px;
  right: 24px;
  font-size: 14px;
}

.make-payment-page .inner-payment .right iframe {
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.make-payment-page .inner-payment .left {
  width: calc(100% - 460px);
}

.make-payment-page .inner-payment h1 {
  color: #0c0c0c;
  font-size: 32px;
  font-weight: 600;
}

.make-payment-page .inner-payment h2 {
  color: #0c0c0c;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 30px;
}

.make-payment-page .inner-payment h3 {
  color: #0c0c0c;
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
}

.make-payment-page .inner-payment h4 {
  color: #0c0c0c;
  font-size: 24px;
  margin-top: 30px;
  font-weight: 600;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.make-payment-page .inner-payment h4 span {
  color: #888;
  font-size: 16px;
  font-weight: 400;
  text-decoration: line-through;
}

.make-payment-page .inner-payment hr {
  margin: 30px 0;
}

.make-payment-page .inner-payment .left .course-contain {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 20px;
}

.make-payment-page .inner-payment .left .course-contain .one {
  display: flex;
  align-items: center;
}

.make-payment-page .inner-payment .left .course-contain .one span {
  margin-left: 15px;
}

@media (max-width: 1600px) {
  .make-payment-page .inner-payment .right {
    width: 350px;
  }

  .make-payment-page .inner-payment .left {
    width: calc(100% - 374px);
  }
}

@media (max-width: 1400px) {
  .make-payment-page .inner-payment .right {
    width: 300px;
  }

  .make-payment-page .inner-payment .left {
    width: calc(100% - 324px);
  }

  .make-payment-page .inner-payment h1 {
    font-size: 28px;
  }

  .make-payment-page .inner-payment h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .make-payment-page .inner-payment h3 {
    font-size: 16px;
    margin-top: 20px;
  }

  .make-payment-page .inner-payment h4 {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .make-payment-page .inner-payment {
    flex-direction: column-reverse;
  }

  .make-payment-page .inner-payment .right {
    width: 100%;
    margin-bottom: 24px;
  }

  .make-payment-page .inner-payment .left {
    width: 100%;
  }

  .make-payment-page .inner-payment h1 {
    font-size: 24px;
  }
}

@media (max-width: 768px) {}
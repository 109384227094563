.pdf-controller {
    width: calc(100% - 48px);
    position: fixed;
    top: 24px;
    right: 24px;
    left: 24px;
    z-index: 10;
}

.pdf-controller button {
    box-shadow: 0px 0px 1px 0px #0c1a4b3d, 0px 3px 8px -1px #3232470d;
    background-color: var(--green);
    color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}
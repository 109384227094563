:root {
  --green: #42ab43;
  --yellow: #ffd600;
}

html, body {
  background-color: #f6f7f9;
  scroll-behavior: smooth;
}

.disable-copy {
  /* Disable all pointer actions */
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

* {
  font-family: "Manrope";
}

input[type="checkbox"] {
  width: 20px !important;
  height: 20px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  font-size: 0.8em;
  text-align: center;
  border: 1px solid #0c0c0c !important;
  border-radius: 4px;
  cursor: pointer;
}

input[type="checkbox"]:checked {
  background: var(--green);
  border: none !important;
}

input[type="checkbox"]:checked:after {
  content: "✔";
  color: white;
}

.container {
  padding: 20px 277px;
}

.header1 {
  color: #000;
  font-size: 40px;
  font-weight: 600;
}

.logo {
  width: 120px;
}

.yellow-btn {
  border: none;
  height: 48px;
  border-radius: 100px;
  background: var(--yellow);
  padding: 0px 32px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.one-id-btn {
  border: none;
  height: 36px;
  border-radius: 100px;
  background: linear-gradient(180deg, #5D30FF 0%, #4825C2 100%);
  padding: 8px 16px;
  font-size: 14px;
  color: white;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.green-btn {
  width: 100%;
  border: none;
  height: 48px;
  border-radius: 100px;
  background: var(--green);
  padding: 0px 32px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.green-btn:disabled {
  cursor: not-allowed;
  background-color: #42ab43;
}

.green-btn:disabled {
  opacity: 0.4;
}

.white-btn {
  width: 100%;
  height: 48px;
  border-radius: 100px;
  border: 1px solid var(--black, #0c0c0c);
  padding: 0px 32px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 50px !important;
  padding-top: 10px !important;
}

.swiper-pagination-bullet {
  width: 16px !important;
  height: 16px !important;
  border: 1px solid #888 !important;
  background-color: transparent !important;
}

.swiper-pagination-bullet-active {
  width: 16px !important;
  height: 16px !important;
  background-color: #fff !important;
}

.top-navigator {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  overflow-x: auto;
  white-space: nowrap;
}

.top-navigator div {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  transition: 0.3s;
}

.top-navigator svg {
  stroke: #0c0c0c;
  transition: 0.3s;
}

.top-navigator div.active {
  color: var(--green);
}

.top-navigator div:hover {
  color: var(--green);
}

.top-navigator div:hover svg {
  stroke: var(--green);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--green);
  border: none;
}

.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: var(--green) !important;
}

.ant-pagination {
  text-align: center;
  margin: 50px 0;
}

.ant-pagination li {
  width: 48px !important;
  height: 48px !important;
  border-radius: 50% !important;
  position: relative;
}

.ant-pagination li a {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: 600;
}

.ant-pagination-item {
  width: 48px !important;
  height: 48px !important;
  border-radius: 50% !important;
  background-color: transparent !important;
}

.ant-pagination-item.ant-pagination-item-active {
  background-color: #000 !important;
  border: none;
}

.ant-pagination-item-active a {
  color: #fff !important;
}

.box {
  background-color: #ffffff;
  padding: 32px;
  border-radius: 32px;
}

.ant-upload-wrapper .ant-upload-drag:hover {
  border: 1px dashed var(--green) !important;
}

.ant-rate-star {
  margin: 0 !important;
}

@media (max-width: 1600px) {
  .container {
    padding: 20px 200px;
  }

  .yellow-btn {
    height: 40px;
    padding: 0px 32px;
    font-size: 14px;
  }

  .green-btn {
    height: 40px;
    padding: 0px 32px;
    font-size: 14px;
  }

  .white-btn {
    height: 40px;
    padding: 0px 32px;
    font-size: 14px;
  }
}

@media (max-width: 1400px) {
  .container {
    padding: 20px 150px;
  }

  .logo {
    width: 140px;
  }

  .header1 {
    font-size: 32px;
  }
}

@media (max-width: 1200px) {
  .container {
    padding: 20px 100px;
  }

  .logo {
    width: 100px;
  }

  .header1 {
    font-size: 28px;
  }
}

@media (max-width: 992px) {
  .container {
    padding: 20px;
  }

  .logo {
    width: 90px;
  }

  .header1 {
    font-size: 24px;
  }

  .box {
    padding: 20px;
    border-radius: 24px;
  }
}

@media (max-width: 768px) {
  .box {
    padding: 10px;
    border-radius: 16px;
  }

  .logo {
    width: 80px;
  }
}
.instructor-detail-page {
  min-height: 100vh;
}

.instructor-detail-page h1 {
  color: #0c0c0c;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 600;
}

.instructor-detail-page .wrapper {
  display: flex;
  justify-content: space-between;
}

.instructor-detail-page .wrapper > .left {
  width: calc(100% - 464px);
  height: min-content;
}

.instructor-detail-page .wrapper .left .about-techer {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}

.instructor-detail-page .wrapper .left .about-techer .bg {
  width: 120px;
  height: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #00000020;
  border-radius: 50%;
  margin-right: 24px;
}

.instructor-detail-page .wrapper .left .about-techer .texts-wrap {
  width: calc(100% - 140px);
}

.instructor-detail-page .wrapper .left .about-techer h1 {
  color: #0c0c0c;
  font-size: 32px;
  font-weight: 600;
  line-height: 130%; /* 41.6px */
  margin-bottom: 0;
}

.instructor-detail-page .wrapper .left .about-techer p {
  color: #0c0c0c;
  font-size: 18px;
  font-weight: 400;
}

.instructor-detail-page .wrapper .right {
  width: 440px;
}

.instructor-detail-page .wrapper .top {
  margin-bottom: 24px;
}

.instructor-detail-page .wrapper .right .line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.instructor-detail-page .wrapper .right .line:last-child {
  margin-bottom: 0;
}

.instructor-detail-page .wrapper .right .line p {
  width: calc(100% - 40px);
  margin: 0;
}

.ant-steps-item-title {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 18px !important;
}

@media (max-width: 1600px) {
  .instructor-detail-page .wrapper .right {
    width: 350px;
  }
  .instructor-detail-page .wrapper > .left {
    width: calc(100% - 374px);
  }
}

@media (max-width: 1400px) {
  .instructor-detail-page .wrapper .right {
    width: 300px;
  }
  .instructor-detail-page .wrapper > .left {
    width: calc(100% - 324px);
  }
  .instructor-detail-page h1 {
    margin-bottom: 24px;
    font-size: 20px;
  }
  .instructor-detail-page .wrapper .left .about-techer p {
    font-size: 16px;
  }
  .instructor-detail-page .wrapper .left .about-techer h1 {
    font-size: 24px;
    line-height: 130%; /* 41.6px */
  }
  .instructor-detail-page .wrapper .right .line p {
    width: calc(100% - 30px);
    font-size: 14px;
  }
  .instructor-detail-page .wrapper .left .about-techer .bg {
    width: 96px;
    height: 96px;
  }
  .instructor-detail-page .wrapper .left .about-techer .texts-wrap {
    width: calc(100% - 110px);
  }
}

@media (max-width: 992px) {
  .instructor-detail-page .wrapper {
    flex-direction: column-reverse;
  }
  .instructor-detail-page .wrapper .right {
    width: 100%;
    margin-bottom: 24px;
  }
  .instructor-detail-page .wrapper > .left {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .instructor-detail-page .wrapper .left .about-techer .bg {
    width: 64px;
    height: 64px;
  }
  .instructor-detail-page .wrapper .left .about-techer .texts-wrap {
    width: calc(100% - 80px);
  }
  .instructor-detail-page .wrapper .left .about-techer h1 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.footer-component {
  background-color: #ffffff;
  border-radius: 64px 64px 0 0;
}

.footer-inner {
  padding: 60px 0;
  display: grid;
  grid-template-columns: calc(20% - 20px) calc(20% - 20px) calc(30% - 20px) calc(
      30% - 20px
    );
  gap: 20px;
  justify-content: space-between;
}

.footer-component .one h6 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.footer-component .one svg {
  margin-right: 10px;
}

.footer-component .one p {
  width: calc(100% - 34px);
  color: #888;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.footer-component .one > div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.footer-bottom {
  text-align: center;
}

.footer-bottom p {
  color: #888;
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 992px) {
  .footer-component {
    border-radius: 32px 32px 0 0;
  }
  .footer-inner {
    padding: 30px;
    grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .footer-component {
    border-radius: 24px 24px 0 0;
  }
  .footer-inner {
    padding: 20px;
    grid-template-columns: 100%;
  }
}

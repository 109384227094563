.my-wallet-page {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
}

.my-wallet-page .right {
  width: calc(100% - 464px);
  height: max-content;
}

.my-wallet-page h1 {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 600;
}

.my-wallet-page p {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.my-wallet-page .right .btn-wrap {
  width: 350px;
  margin-top: 20px;
}

.my-wallet-page .right hr {
  margin: 24px 0;
}

.my-wallet-page .right .wallet-card {
  position: relative;
  width: 350px;
  height: 200px;
  border-radius: 10px;
  background: linear-gradient(90deg, #0fd850 0%, #f9f047 100%);
  margin-top: 30px;
  transition: 0.3s;
  cursor: pointer;
}

.my-wallet-page .right .wallet-card span {
  position: absolute;
  top: 16px;
  left: 24px;
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
}

.my-wallet-page .right .wallet-card h6 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
}

.my-wallet-page .right .wallet-card h6 strong {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.48px;
}

.my-wallet-page .right .wallet-card em {
  position: absolute;
  bottom: 16px;
  right: 24px;
  font-size: 14px;
}

.my-wallet-page .right iframe {
  width: 100%;
  height: 500px;
  border-radius: 8px;
}

@media (max-width: 1600px) {
  .my-wallet-page .right {
    width: calc(100% - 375px);
    height: max-content;
  }
}

@media (max-width: 1400px) {
  .my-wallet-page .right {
    width: calc(100% - 320px);
    height: max-content;
  }

  .my-wallet-page h1 {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .my-wallet-page {
    flex-direction: column;
  }

  .my-wallet-page .right {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .my-wallet-page .right .wallet-card {
    width: 100%;
    height: 200px;
    margin-top: 20px;
  }

  .my-wallet-page .right .btn-wrap {
    width: 100%;
  }


  .my-wallet-page .right iframe {
    height: 250px;
  }
}
@keyframes ldio-0f86q94y2f5h {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5, 1.5);
  }

  100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);
  }
}

.loader-component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ldio-0f86q94y2f5h div>div {
  position: absolute;
  width: 24.119999999999997px;
  height: 24.119999999999997px;
  border-radius: 50%;
  background: #42ab43;
  animation: ldio-0f86q94y2f5h 1s linear infinite;
}

.ldio-0f86q94y2f5h div:nth-child(1)>div {
  left: 148.5px;
  top: 88.49999999999999px;
  animation-delay: -0.875s;
}

.ldio-0f86q94y2f5h>div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 160.56px 100.55999999999999px;
}

.ldio-0f86q94y2f5h div:nth-child(2)>div {
  left: 131.5px;
  top: 131.5px;
  animation-delay: -0.75s;
}

.ldio-0f86q94y2f5h>div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 143.56px 143.56px;
}

.ldio-0f86q94y2f5h div:nth-child(3)>div {
  left: 88.49999999999999px;
  top: 148.5px;
  animation-delay: -0.625s;
}

.ldio-0f86q94y2f5h>div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 100.55999999999999px 160.56px;
}

.ldio-0f86q94y2f5h div:nth-child(4)>div {
  left: 45.499999999999986px;
  top: 131.5px;
  animation-delay: -0.5s;
}

.ldio-0f86q94y2f5h>div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 57.55999999999999px 143.56px;
}

.ldio-0f86q94y2f5h div:nth-child(5)>div {
  left: 28.499999999999986px;
  top: 88.49999999999999px;
  animation-delay: -0.375s;
}

.ldio-0f86q94y2f5h>div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 40.55999999999999px 100.55999999999999px;
}

.ldio-0f86q94y2f5h div:nth-child(6)>div {
  left: 45.499999999999986px;
  top: 45.499999999999986px;
  animation-delay: -0.25s;
}

.ldio-0f86q94y2f5h>div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 57.55999999999999px 57.55999999999999px;
}

.ldio-0f86q94y2f5h div:nth-child(7)>div {
  left: 88.49999999999999px;
  top: 28.499999999999986px;
  animation-delay: -0.125s;
}

.ldio-0f86q94y2f5h>div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 100.55999999999999px 40.55999999999999px;
}

.ldio-0f86q94y2f5h div:nth-child(8)>div {
  left: 131.5px;
  top: 45.499999999999986px;
  animation-delay: 0s;
}

.ldio-0f86q94y2f5h>div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 143.56px 57.55999999999999px;
}

.loadingio-spinner-spin-dftkvc0y2q5 {
  width: 201px;
  height: 201px;
  display: inline-block;
  overflow: hidden;
  background: rgba(NaN, NaN, NaN, 0);
}

.ldio-0f86q94y2f5h {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-0f86q94y2f5h div {
  box-sizing: content-box;
}

@media (max-width: 992px) {
  .loader-component {
    transform: scale(0.8);
  }
}

@media (max-width: 576px) {
  .loader-component {
    transform: scale(0.6);
  }
}
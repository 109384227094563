.home-page {
  min-height: 100vh;
}

.home-page section {
  margin-bottom: 150px;
}

/* HOME BANNER */
.home-banner {
  border-radius: 32px;
  width: 100%;
  height: 650px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: url('../../assets/images/home-banner.png');
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px;
}

.home-banner .left {
  padding: 50px;
  width: 50%;
  background-color: #fff;
  border-radius: 8px;
}

.home-banner .left h3 {
  color: var(--green);
  font-size: 24px;
  font-weight: 700;
}

.home-banner .left h2 {
  color: #000;
  font-size: 46px;
  font-weight: 700;
}

.home-banner .left p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
}

.home-banner .search-wrap {
  position: relative;
}

.home-banner .search-wrap input {
  width: 100%;
  height: 56px;
  border-radius: 100px;
  background: #f8f8f8;
  border: none;
  outline: none;
  padding: 20px;
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
}

.home-banner .search-wrap input::placeholder {
  color: #a5a5a5;
}

.home-banner .search-wrap button {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
}

/* STATISTICS */

.home-page .one-statistics {
  width: 100%;
  height: 170px;
  border-radius: 24px;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 20px;
  padding-left: 40px;
  transition: 0.3s;
}

.home-page .one-statistics:hover {
  transform: translateY(-10px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.home-page .one-statistics p span {
  color: var(--green);
  font-size: 48px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
}

.home-page .one-statistics span {
  color: #000;
  font-size: 24px;
}

.home-page .header-p {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
  margin-bottom: 50px;
}

.home-page .one-skill {
  width: 100%;
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 358px;
  border-radius: 24px;
  background: #ddffdc;
  transition: 0.3s;
}

.home-page .one-skill.one {
  background: #ddffdc;
}

.home-page .one-skill.two {
  background: #fffbd9;
}

.home-page .one-skill.three {
  background: #ead9ff;
}

.home-page .one-skill.four {
  background: #dcf5ff;
}

.home-page .one-skill:hover {
  transform: translateY(-10px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.home-page .one-skill .circle {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #000;
}

.home-page .one-skill .circle svg {
  width: 32px;
  height: 32px;
}

.home-page .one-skill h2 {
  color: #0f0f0f;
  font-size: 24px;
  font-weight: 600;
  line-height: 130%;
  /* 31.2px */
  margin: 20px 0;
}

.home-page .one-skill p {
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

@media (max-width: 1800px) {
  .home-banner .left h2 {
    font-size: 32px;
  }

  .home-banner .right img {
    width: 450px;
  }
}

@media (max-width: 1600px) {
  .home-banner {
    height: 600px;
  }

  .home-banner .left h2 {
    font-size: 32px;
  }

  .home-banner .right img {
    width: 400px;
  }

  .home-banner .search-wrap input {
    height: 46px;
    border-radius: 100px;
    padding: 20px;
    font-size: 16px;
  }

  .home-banner .search-wrap button {
    right: 2px;
  }

  .home-page .one-statistics p span {
    font-size: 36px;
  }

  .home-page .one-statistics span {
    font-size: 18px;
  }
}

@media (max-width: 1400px) {
  .home-banner {
    height: 500px;
  }

  .home-banner .left {
    padding: 30px;
    width: 50%;
  }

  .home-banner .right {
    padding: 50px;
    padding-bottom: 0;
  }

  .home-page section {
    margin-bottom: 100px;
  }
}

@media (max-width: 1200px) {
  .home-banner {
    height: 450px;
  }

  .home-banner .left h2 {
    font-size: 28px;
  }

  .home-banner .right img {
    width: 300px;
  }

  .home-banner .left h3 {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .home-banner {
    padding: 20px;
  }

  .home-banner .left {
    padding: 20px;
    width: 60%;
  }

  .home-banner .right {
    padding: 20px;
    padding-bottom: 0;
  }

  .home-banner .right img {
    width: 250px;
  }
}

@media (max-width: 768px) {
  .home-banner {
    height: 350px;
  }

  .home-banner .right img {
    width: 200px;
  }
}

@media (max-width: 576px) {
  .home-banner {
    height: auto;
    padding: 00px;
    border-radius: 8px;
  }

  .home-banner .right {
    display: none;
  }

  .home-banner .left {
    padding: 20px;
    width: 100%;
    background-color: #ffffff90;
  }

  .home-banner .left h2 {
    font-size: 24px;
  }

  .home-page .one-skill {
    padding: 0 30px;
    height: 350px;
  }

  .home-page .one-skill .circle {
    width: 48px;
    height: 48px;
  }

  .home-page .one-skill .circle svg {
    width: 24px;
    height: 24px;
  }

  .home-page .one-skill h2 {
    font-size: 18px;
    margin: 15px 0;
  }
}
.one-news {
  border-radius: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #00000020;
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}

.one-news:hover {
  transform: translateY(-10px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.one-news .bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0.4) 179.86%);
  backdrop-filter: blur(10px);
  padding: 24px;
}

.one-news .bottom h2 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

.one-news .bottom>div {
  display: flex;
  justify-content: flex-end;
}

.one-news .bottom p {
  color: #888;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  margin-left: 10px;
}

@media (max-width: 992px) {
  .one-news {
    border-radius: 24px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .one-news {
    border-radius: 16px;
  }
}
.news-detail-page {
  min-height: 100vh;
}

.news-detail-page .news-content .bg-wrap {
  width: 100%;
  height: 630px;
  border-radius: 32px;
  overflow: hidden;
  margin-bottom: 30px;
}

.news-detail-page .news-content .bg {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.3s;
}

.news-detail-page .news-content .bg:hover {
  transform: scale(1.05);
}

.news-detail-page .news-content .top {
  display: flex;
  justify-content: space-between;
}

.news-detail-page .news-content .top h1 {
  color: #0c0c0c;
  font-size: 40px;
  font-weight: 600;
}

.news-detail-page .news-content .top>div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 300px;
}

.news-detail-page .news-content .top>div span {
  color: #888;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px;
}

.news-detail-page .news-content p {
  color: #464646;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.news-detail-page h3 {
  color: #0c0c0c;
  font-size: 32px;
  font-weight: 600;
}

.news-detail-page section {
  margin-top: 100px;
}

@media (max-width: 1400px) {
  .news-detail-page .news-content .bg-wrap {
    height: 500px;
  }

  .news-detail-page .news-content .top h1 {
    font-size: 32px;
  }

  .news-detail-page h3 {
    font-size: 24px;
  }
}

@media (max-width: 992px) {
  .news-detail-page .news-content .bg-wrap {
    height: 400px;
    border-radius: 24px;
    margin-bottom: 10px;
  }

  .news-detail-page .news-content .top {
    flex-direction: column-reverse;
  }

  .news-detail-page .news-content .top>div {
    margin-left: auto;
    margin-bottom: 10px;
  }

  .news-detail-page .news-content .top h1 {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .news-detail-page .news-content .bg-wrap {
    height: 300px;
    border-radius: 16px;
  }

  .news-detail-page .news-content .top>div {
    transform: scale(0.9);
  }
}
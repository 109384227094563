.not-found-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.not-found-page .text-container {
  margin: 30px 0;
  text-align: center;
}

.not-found-page .text-container h1 {
  font-size: 40px;
  font-weight: 600;
}

.not-found-page .text-container p {
  font-size: 16px;
  color: #888;
}

@media (max-width: 768px) {
  .not-found-page .svg-wrap {
    transform: scale(0.6);
  }
  .not-found-page .text-container h1 {
    font-size: 28px;
  }
  .not-found-page .text-container p {
    font-size: 14px;
  }
  .not-found-page .text-container {
    margin: 15px 0;
  }
}

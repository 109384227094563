.modal-inner .warning-wrap {
  padding: 20px;
  background-color: hsla(0, 100%, 91%, 0.3);
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid #d71b1b;
  max-height: 500px;
  overflow: auto;
}

.modal-inner ul {
  text-align: justify;
}

.modal-inner .warning-wrap.yellow {
  background-color: transparent;
  border: 1px solid var(--green);
  color: var(--green);
}

.offerta-modal .warning {
  padding: 0;
  text-align: center;
}

.offerta-modal .warning-icon {
  color: #d71b1b;
  font-size: 48px;
  margin: auto;
}

.offerta-modal .warning-icon.yellow {
  color: var(--green);
}

.offerta-modal .btn-wrap {
  display: flex;
  gap: 10px;
  justify-content: center;
  text-align: center;
}

.offerta-modal .btn-wrap button.btn-red {
  background: #eb8d8d;
  width: 200px;
  height: 36px;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: not-allowed;
}

.offerta-modal .btn-wrap button.btn-active {
  background: #d71b1b;
  cursor: pointer;
}

.offerta-modal .btn-wrap button.btn-green {
  background-color: var(--green);
  cursor: pointer;
}

.offerta-modal .bottom-wrap {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 50px;
  text-align: justify;
}

@media (max-width: 768px) {
  .offerta-modal .bottom-wrap {
    display: grid;
    grid-template-columns: 100%;
    gap: 20px;
    text-align: justify;
  }
}
.one-comment {
  width: 100%;
  border-radius: 16px;
  background: #fff;
  padding: 30px;
  position: relative;
  transition: 0.3s;
}

.one-comment:hover {
  transform: translateY(-10px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.one-comment .bg {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-image: url("../../assets/images/user.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  float: left;
  margin: 0px 20px 10px 0px;
}

.one-comment p {
  color: #0f0f0f;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.one-comment span {
  position: absolute;
  right: 20px;
  bottom: 20px;
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 576px) {
  .one-comment .bg {
    width: 48px;
    height: 48px;
    margin: 0px 15px 5px 0px;
  }
}
.course-detail-page {
  width: 100%;
  min-height: 100vh;
}

.course-detail-page .course-details .bg-course-wrapper {
  overflow: hidden;
  border-radius: 32px;
  margin-bottom: 30px;
}

.course-detail-page .course-details .bg-course-wrapper .bg-course {
  width: 100%;
  height: 634px;
  background-color: #00000020;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.3s;
}

.course-detail-page .course-details .bg-course-wrapper .bg-course:hover {
  transform: scale(1.05);
}

.course-detail-page .course-details .course-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.course-detail-page .course-details .course-content .left {
  width: calc(100% - 470px);
}

.course-detail-page .course-details .course-content .left p {
  color: #464646;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.course-detail-page .course-details .course-content .left hr {
  margin: 30px 0;
}

.course-detail-page .course-details .course-content .left h2 {
  color: #0c0c0c;
  font-size: 20px;
  font-weight: 500;
  font-weight: 600;
}

.course-detail-page .course-details .course-content .left .about-teacher .bg-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.course-detail-page .course-details .course-content .left .about-teacher .bg-wrap .bg {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #00000020;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 16px;
}

.course-detail-page .course-details .course-content .left .about-teacher h4 {
  color: #0c0c0c;
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.course-detail-page .course-details .course-content .left .about-teacher span {
  color: #888;
  font-size: 16px;
  font-weight: 400;
}

.course-detail-page .course-content .one-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #00000020;
}

.course-detail-page .course-content .one-row:last-child {
  margin: 0;
  border: none;
}

.course-detail-page .course-content .one-row p {
  width: 80px;
  text-align: right;
  margin: 0;
  font-size: 14px;
}

.course-detail-page .course-content .one-row>div {
  width: calc(100% - 100px);
  display: flex;
  align-items: flex-start;
  justify-content: left;
}

.course-detail-page .course-content .one-row span {
  font-size: 16px;
  margin-left: 10px;
}

.course-detail-page .course-details .course-content .right .top {
  height: min-content;
  width: 430px;
  border-radius: 24px;
  background: #fff;
  box-shadow: 0px 6px 30px 0px rgba(0, 7, 43, 0.08);
  padding: 40px;
  transition: 0.3s;
  position: sticky;
  top: 125px;
}

/* .course-detail-page .course-details .course-content .right .top:hover {
  transform: translateY(-10px);
} */

.course-detail-page .course-details .course-content .right .top .ul li {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.course-detail-page .course-details .course-content .right .top .ul li p {
  color: #0f0f0f;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  margin-left: 16px;
}

.course-detail-page .course-details .course-content .right .top button {
  margin-top: 16px;
}

.course-detail-page .course-details .course-content .right .top .reyting {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.course-detail-page .course-details .course-content .right .top .reyting h6 {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin-left: 10px;
}

.course-detail-page .course-details .course-content .right .top .reyting p {
  color: #888;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  margin-left: 10px;
}

.course-detail-page .course-details .course-content h1 {
  color: #000;
  font-size: 32px;
  font-weight: 600;
}

.course-detail-page .course-details .course-content h1 span {
  text-decoration: line-through;
  margin-left: auto;
  font-size: 18px;
  color: #888;
}

.share-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.share-container,
.course-detail-page .icon {
  font-size: 24px;
  color: var(--green);
  cursor: pointer;
}

.share-container p {
  margin: 0;
  font-size: 18px;
  margin-left: 10px;
}

.all-comments {
  width: 100%;
}

.all-comments .comment-container {
  border-bottom: 1px solid #00000020;
  padding: 20px 0;
}

.all-comments .comment-container:last-child {
  border-bottom: none;
}

.all-comments .comment-container .top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.all-comments .comment-container .top .left-side {
  display: flex;
  align-items: center;
}

.all-comments .comment-container .top .user-img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  /* background-color: #00000020; */
  margin-right: 10px;
  object-fit: contain;
}

.all-comments .comment-container .top .date {
  font-size: 13px;
}

.all-comments .comment-container .top h5 {
  font-size: 16px;
  margin-bottom: 5px;
}

.all-comments .comment-container p {
  font-size: 14px;
  color: #888;
  margin: 0;
}

.more-btn {
  display: flex;
  justify-content: space-between;
  width: auto;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.more-btn:hover {
  color: var(--green);
}

@media (max-width: 1400px) {
  .course-detail-page .course-details .bg-course-wrapper {
    border-radius: 32px;
    margin-bottom: 20px;
  }

  .course-detail-page .course-details .bg-course-wrapper .bg-course {
    height: 500px;
  }

  .course-detail-page .course-details .course-content h1 {
    font-size: 28px;
  }

  .course-detail-page .course-details .course-content .left hr {
    margin: 20px 0;
  }

  .course-detail-page .course-details .course-content .left h2 {
    font-size: 18px;
  }

  .course-detail-page .course-details .course-content .left .about-teacher h4 {
    font-size: 18px;
  }

  .course-detail-page .course-details .course-content .left {
    width: calc(100% - 370px);
  }

  .course-detail-page .course-details .course-content .left p {
    font-size: 16px;
  }

  .course-detail-page .course-details .course-content .right .top {
    width: 350px;
    border-radius: 16px;
    padding: 20px;
    top: 120px;
  }
}

@media (max-width: 1200px) {
  .course-detail-page .course-details .bg-course-wrapper .bg-course {
    height: 400px;
  }
}

@media (max-width: 992px) {
  .course-detail-page .course-details .bg-course-wrapper {
    border-radius: 24px;
  }

  .course-detail-page .course-details .bg-course-wrapper .bg-course {
    height: 300px;
  }

  .course-detail-page .course-details .course-content {
    flex-direction: column-reverse;
  }

  .course-detail-page .course-details .course-content .left {
    width: 100%;
  }

  .course-detail-page .course-details .course-content .right .top {
    margin-left: auto;
    margin-bottom: 20px;
    width: 100%;
    border-radius: 16px;
  }

  .course-detail-page .course-details .course-content h1 {
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .course-detail-page .course-details .bg-course-wrapper {
    border-radius: 16px;
  }

  .course-detail-page .course-details .bg-course-wrapper .bg-course {
    height: 300px;
  }
}

@media (max-width: 576px) {
  .course-detail-page .course-details .course-content .right .top .reyting {
    margin: 10px 0;
  }

  .course-detail-page .course-details .course-content .right .top .reyting h6 {
    font-size: 14px;
  }

  .course-detail-page .course-details .course-content .right .top .reyting p {
    font-size: 12px;
    margin-left: 5px;
  }

  .course-detail-page .course-details .course-content h1 {
    font-size: 18px;
  }
}
.wrapper-offerta {
    overflow: auto;
}

.view-offerta {
    width: 21cm;
    margin: auto;
    color: #000;
    text-indent: 20px;
    text-align: justify;
    font-size: 16px;
    padding: 27mm 16mm 27mm 16mm;
    padding-top: 0;
}

/* @media print {} */

.view-offerta li, .view-offerta b {
    margin: 5px 0;
    line-height: 1.5;
}

.view-offerta table {
    width: 100%;
}

.view-offerta table, .view-offerta tr, .view-offerta td, .view-offerta th {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 5px;
    text-align: center;
}

.view-offerta .bottom-wrap {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 50px;
    text-align: justify;
}

/* @media (max-width: 768px) {
    .view-offerta {
        width: 100%;
        margin: auto;
        color: #000;
        text-indent: 20px;
        text-align: justify;
        font-size: 16px;
        padding: 27mm 16mm 27mm 16mm;
        padding-top: 0;
    }
} */
.view-course-page {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
}

.view-course-page .right {
  width: calc(100% - 464px);
  /* height: max-content; */
  max-height: calc(100vh - 200px);
  overflow: auto;
  position: sticky;
  top: 120px;
}

.view-course-page h1 {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.view-course-page p {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  /* 24px */
}

.view-course-page .tab {
  display: flex;
}

.view-course-page .tab h5 {
  font-size: 18px;
  padding: 10px;
  cursor: pointer;
}

.view-course-page .tab h5.active {
  color: var(--green);
}

.view-course-page .useful li {
  font-size: 16px;
  margin-bottom: 10px;
  transition: 0.3s;
}

.view-course-page .useful li:hover {
  color: var(--green);
}

/* MAIN */

.view-course-page .bg-wrap {
  width: 100%;
  height: 380px;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 32px;
  margin: 20px 0;
}

.view-course-page .bg-wrap .bg {
  width: 100%;
  height: 100%;
  background-color: #00000020;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.3s;
}

.view-course-page .bg-wrap:hover .bg {
  transform: scale(1.05);
}

.view-course-page .video-wrap iframe {
  width: 100%;
  height: 380px;
  border-radius: 32px;
  margin-bottom: 24px;
}

.view-course-page .video-js {
  width: 100%;
  height: 380px;
  border-radius: 32px;
  margin-bottom: 24px;
}

/* QUIZ-PAGE */
.quiz-page p {
  color: #888;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.quiz-page .ant-radio-group {
  width: 100%;
}

.quiz-page .ant-radio-wrapper {
  padding: 13px 25px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row-reverse;
  width: 100% !important;
  justify-content: flex-end;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.quiz-page .ant-radio-wrapper .ant-radio-input {
  width: 24px;
  height: 24px;
}

.quiz-page .ant-radio-wrapper span {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.quiz-page .ant-radio-wrapper .ant-radio {
  margin-left: auto;
}

.quiz-page .one-quiz {
  margin-bottom: 32px;
}

.quiz-page .one-quiz .top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.quiz-page .one-quiz .top h3 {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

.quiz-page .one-quiz .top span {
  width: 100px;
  color: #888;
  text-align: right;
  font-size: 14px;
  font-weight: 400;
}

.results-modal h1 {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
}

.results-modal p {
  color: #888;
  font-size: 16px;
  font-weight: 400;
}

.results-modal .texts {
  text-align: center;
  margin: 20px 0;
}

.results-modal h4 {
  color: #0c0c0c;
  font-size: 20px;
  font-weight: 400;
}

.results-modal .btn-wrap {
  display: flex;
  justify-content: space-between;
}

.results-modal .btn-wrap button {
  width: 240px;
  font-size: 14px;
}

.comment-container h1 {
  font-size: 24px;
  margin-bottom: 10px;
}

.comment-container p {
  font-size: 16px;
  color: #888;
}

.comment-container .rate-icon {
  font-size: 32px;
  margin-bottom: 30px;
}

.comment-container .wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.comment-container .wrap label {
  font-size: 16px;
  color: #888;
  margin-bottom: 5px;
}

.comment-container .wrap textarea {
  width: 100%;
  min-height: 150px;
  border: 1px solid #888;
  padding: 10px;
  border-radius: 8px;
}

.view-course-page .timer-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.success-error-img {
  width: 100px;
  margin: auto;
}

@media (max-width: 1400px) {
  .view-course-page .bg-wrap {
    height: 300px;
    border-radius: 32px;
  }
}

@media (max-width: 1200px) {
  .view-course-page {
    flex-direction: column-reverse;
  }

  .view-course-page .right {
    width: 100%;
    margin-bottom: 20px;
    position: static;
  }
}

@media (max-width: 992px) {
  .view-course-page .bg-wrap {
    height: 300px;
    border-radius: 24px;
  }

  .view-course-page .video-wrap iframe {
    height: 380px;
    border-radius: 16px;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .view-course-page .bg-wrap {
    height: 250px;
    border-radius: 24px;
  }

  .view-course-page .video-wrap iframe {
    height: 300px;
    border-radius: 12px;
  }
}

@media (max-width: 576px) {
  .results-modal .btn-wrap {
    flex-direction: column;
    gap: 10px;
  }

  .results-modal .btn-wrap button {
    width: 100%;
  }
}
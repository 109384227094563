.sidebar-component {
  width: 439px;
}

.sidebar-component .top {
  width: 100%;
  border-radius: 32px;
  background-color: #fff;
  padding: 50px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
}

.sidebar-component .top .bg {
  width: 215px;
  height: 215px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  background-color: #f8f8f8;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 16px;
  font-weight: 500;
}

.sidebar-component .bottom {
  width: 100%;
  border-radius: 32px;
  background-color: #fff;
  padding: 50px 0px;
}

.sidebar-component .bottom .row-link {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.sidebar-component .bottom .row-link:last-child {
  margin-bottom: 0;
}

.sidebar-component .bottom .row-link .left-line {
  width: 8px;
  height: 100%;
  border-radius: 0px 6px 6px 0px;
  background: #fff;
  margin-right: 50px;
  transition: 0.3s;
}

.sidebar-component .bottom .row-link h3 {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  margin-left: 10px;
  transition: 0.3s;
}

.sidebar-component .bottom .row-link svg {
  stroke: #0c0c0c;
  transition: 0.3s;
}

.sidebar-component .bottom .row-link:hover .left-line {
  background: var(--green);
}

.sidebar-component .bottom .row-link:hover h3 {
  color: var(--green);
}

.sidebar-component .bottom .row-link:hover svg {
  stroke: var(--green);
}

.sidebar-component .bottom .logout-row svg {
  stroke: red;
}

.sidebar-component .bottom .logout-row:hover svg {
  stroke: red;
}

.sidebar-component .bottom .row-link.active .left-line {
  background: var(--green);
}

.sidebar-component .bottom .logout-row:hover .left-line {
  background: red;
}

.sidebar-component .bottom .row-link.active h3 {
  color: var(--green);
}

.sidebar-component .bottom .row-link.active svg {
  stroke: var(--green);
}

@media (max-width: 1600px) {
  .sidebar-component {
    width: 350px;
  }
}

@media (max-width: 1400px) {
  .sidebar-component {
    width: 300px;
  }

  .sidebar-component .top {
    border-radius: 32px;
    padding: 30px;
    margin-bottom: 20px;
  }

  .sidebar-component .top .bg {
    width: 215px;
    height: 215px;
    margin-bottom: 20px;
  }

  .sidebar-component .bottom {
    border-radius: 32px;
    padding: 30px 0px;
  }

  .sidebar-component .bottom .row-link {
    height: 42px;
    margin-bottom: 10px;
  }

  .sidebar-component .bottom .row-link .left-line {
    width: 5px;
    margin-right: 25px;
  }

  .sidebar-component .bottom .row-link h3 {
    font-size: 16px;
    margin-left: 10px;
  }
}

@media (max-width: 992px) {
  .sidebar-component {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .sidebar-component .top {
    width: calc(50% - 10px);
    height: max-content;
    border-radius: 24px;
    padding: 20px;
    margin: 0;
  }

  .sidebar-component .bottom {
    width: calc(50% - 10px);
    border-radius: 24px;
    padding: 20px 0px;
  }
}

@media (max-width: 768px) {
  .sidebar-component .top {
    border-radius: 16px;
    margin-bottom: 20px;
    width: 100%;
  }

  .sidebar-component .bottom {
    border-radius: 16px;
    width: 100%;
  }

  .sidebar-component {
    flex-direction: column;
  }
}
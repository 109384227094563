.one-course {
  min-height: 520px;
  border-radius: 24px;
  background: #fff;
  padding: 16px;
  transition: 0.3s;
  border: 1px solid #00000020;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.one-course:hover {
  transform: translateY(-10px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.one-course .bg-wrap {
  width: 100%;
  height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  margin-bottom: 20px;
}

.one-course .bg-wrap .bg {
  width: 100%;
  height: 200px;
  background-color: #00000020;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  transition: 0.3s;
}

.one-course .bg-wrap .status {
  border-radius: 0px 16px;
  background: var(--green);
  position: absolute;
  top: 0;
  right: 0;
  width: 132px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.one-course .bg-wrap .bg:hover {
  transform: scale(1.1);
}

.one-course p {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%; /* 28px */
  margin: 0;
}

.one-course .course-about {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.one-course .course-about > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.one-course .course-about .span {
  color: #888;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 10px;
}

.one-course .ant-rate * {
  font-size: 24px !important;
}

.one-course .ant-rate-star {
  margin-inline-end: 0 !important;
  margin: 0 !important;
}

.one-course .teacher-about {
  display: flex;
  justify-content: space-between;
  align-items: last baseline;
  margin-bottom: 20px;
  font-size: 12px;
}

.one-course .teacher-about .left {
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
  cursor: pointer;
}

.one-course .teacher-about .left .bg-teacher {
  width: 48px;
  height: 48px;
  background-color: #00000020;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.one-course .teacher-about .left .texts-wrap {
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
}

.one-course .teacher-about .left h2 {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  transition: 0.3s;
}

.one-course .teacher-about .left p {
  color: #888;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  transition: 0.3s;
}

.one-course .teacher-about .left:hover h2 {
  color: #0000a3;
}

.one-course .teacher-about .left:hover p {
  color: #0000a3;
}

.one-course .course-progress {
  width: 100%;
  height: 16px;
  border-radius: 1000px;
  background: #e9e9e9;
  margin: 30px 0;
  position: relative;
}

.one-course .course-progress > div {
  height: 100%;
  border-radius: 1000px;
  background: var(--green, #42ab43);
}

.one-course .course-progress p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  font-weight: 700;
}

@media (max-width: 1700px) {
  .one-course .course-about .span {
    font-size: 12px;
    margin-left: 5px;
  }
  .one-course .ant-rate * {
    font-size: 18px !important;
  }
  .one-course .teacher-about .left .bg-teacher {
    width: 36px;
    height: 36px;
    margin-right: 5px;
  }
  .one-course .teacher-about .left .texts-wrap {
    width: calc(100% - 48px);
  }
  .one-course .teacher-about .left h2 {
    font-size: 15px;
  }
  .one-course .teacher-about .left p {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .one-course {
    min-height: 400px;
  }
}

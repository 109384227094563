.course-sidebar-component {
  width: 439px;
  height: min-content;
  position: sticky;
  top: 120px;
}

/* .course-sidebar-component .ant-collapse-item */

.course-sidebar-component .section-label {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.course-sidebar-component .section-label p {
  width: calc(100% - 40px);
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  padding-left: 35px;
}

.course-sidebar-component .ant-collapse-header {
  padding: 8px !important;
}

.course-sidebar-component .ant-collapse-expand-icon {
  padding: 0 !important;
  margin: 0 !important;
}

.course-sidebar-component .section-label span {
  white-space: nowrap;
  color: #0c0c0c;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
}

.course-sidebar-component .one-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
}

.course-sidebar-component .one-row>div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 30px);
}

.course-sidebar-component .one-row svg {
  stroke: #0c0c0c;
  margin-right: 8px;
  transition: 0.3s;
}

.course-sidebar-component .one-row p {
  width: calc(100% - 32px);
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  transition: 0.3s;
}

.course-sidebar-component .one-row input {
  width: 20px;
  height: 20px;
  border: #0c0c0c;
  margin-left: 20px;
}

.course-sidebar-component .one-row:hover svg {
  stroke: var(--green);
}

.course-sidebar-component .one-row:hover p {
  color: var(--green);
}

.course-sidebar-component .one-row.active p {
  color: var(--green);
}

.course-sidebar-component .one-row.active svg {
  stroke: var(--green);
}

.course-sidebar-component .progress {
  width: 100%;
  height: 16px;
  border-radius: 1000px;
  background: #e9e9e9;
  margin-top: 30px;
}

.course-sidebar-component .progress>div {
  height: 100%;
  border-radius: 1000px;
  background: var(--green, #42ab43);
}

.course-sidebar-component .sidebar-btn {
  width: 100%;
  height: 36px;
  margin-top: 20px;
  background: rgb(248, 248, 248);
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #42ab4350;
}

.course-sidebar-component .sidebar-btn span {
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: #fff;
}

.course-sidebar-component .sidebar-btn .curtain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--green);
  z-index: 0;
}

.course-sidebar-component .sidebar-btn .curtain.yellow {
  background: var(--yellow);
}

.course-sidebar-component .completed-img {
  width: 16px;
}

@media (max-width: 1200px) {
  .course-sidebar-component {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    top: 0;
  }
}
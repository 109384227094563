.my-courses-page {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
}

.my-courses-page .right {
  width: calc(100% - 464px);
  height: max-content;
}

.my-courses-page h1 {
  color: #0c0c0c;
  font-size: 24px;
  font-weight: 600;
}

.my-courses-page p {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

@media (max-width: 1600px) {
  .my-courses-page .right {
    width: calc(100% - 375px);
    height: max-content;
  }
}

@media (max-width: 1400px) {
  .my-courses-page .right {
    width: calc(100% - 320px);
    height: max-content;
  }
  .my-courses-page h1 {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .my-courses-page {
    flex-direction: column;
  } 
  .my-courses-page .right {
    width: 100%;
  }
}
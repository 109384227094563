.navbar-wrap {
  margin-bottom: 120px;
}

.navbar-component {
  width: 100%;
  height: 96px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  background-color: #ddffdc;
}

.navbar-component .links-wrap a {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0 10px;
  padding: 10px 10px;
  text-decoration: none;
  color: #000;
}

.navbar-component .links-wrap a.active {
  color: var(--green);
}

.navbar-wrap .user-wrap {
  display: flex;
  align-items: center;
}

.navbar-wrap .user-wrap svg {
  width: 48px;
  height: 48px;
}

.navbar-wrap .user-wrap .user-bg {
  width: 48px;
  height: 48px;
  background-color: #00000020;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.navbar-wrap .user-wrap h2 {
  color: #0c0c0c;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  margin-left: 10px;
}

.navbar-component .menu {
  width: 36px;
  height: 36px;
  background-color: #00000010;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: none;
  font-size: 20px;
}

.navbar-wrap .mobile-menu {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 10;
  display: none;
  justify-content: space-between;
  transition: 0.3s;
  transform: translateX(-100%);
}

.navbar-wrap .mobile-menu.active {
  transform: translateX(0%);
}

.navbar-wrap .mobile-menu .left {
  width: 300px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  padding: 100px 30px;
  padding-bottom: 30px;
  position: relative;
}

.navbar-wrap .mobile-menu .left .links-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.navbar-wrap .mobile-menu .left .logo {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 10;
}

.navbar-wrap .mobile-menu .left .icon {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 18px;
  color: #888;
  cursor: pointer;
}

.navbar-wrap .mobile-menu .left a {
  font-size: 16px;
  margin: 10px 0;
}

.navbar-wrap .mobile-menu .left a.active {
  color: var(--green);
}

.navbar-wrap .mobile-menu .right {
  width: calc(100% - 300px);
  height: 100%;
}

.lang-user-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.lang-container .selected-lang {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  text-transform: capitalize;
}

@media (max-width: 1600px) {
  .lang-container .selected-lang {
    font-size: 16px;
  }
}

@media (max-width: 1200px) {
  .navbar-component .links-wrap a {
    font-size: 14px;
    padding: 5px 5px;
  }

  .navbar-wrap .user-wrap svg {
    width: 36px;
    height: 36px;
  }

  .navbar-wrap .user-wrap h2 {
    font-size: 14px;
    margin-left: 10px;
  }

  .navbar-wrap .user-wrap .user-bg {
    width: 36px;
    height: 36px;
  }
}

@media (max-width: 992px) {
  .navbar-component .links-wrap {
    display: none;
  }

  .navbar-component .menu {
    display: flex;
  }

  .navbar-wrap .mobile-menu {
    display: flex;
  }
}